import { withExtTenantIdRequest } from '@/utils/request';

// 获取全部的参数信息
export function listPage(data) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/brand/list',
    method: 'post',
    data
  });
}

// 批量删除
export function handleDelete(data) {
  return withExtTenantIdRequest({
    url: `/commodity/api/brand/deleteByIds`,
    method: 'post',
    data
  });
}
// 通过id删除品牌
export function deleteById(id) {
  return withExtTenantIdRequest({
    url: `/commodity/api/brand/delete?id=${id}`,
    method: 'POST'
  });
}

// 新增
export function create(data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/brand/create',
    method: 'post',
    data
  });
}

// 更新
export function update(data) {
  return withExtTenantIdRequest({
    url: '/commodity/api/brand/update',
    method: 'post',
    data
  });
}
// 通过id获取店铺的信息
export function getById(id) {
  return withExtTenantIdRequest({
    url: `/commodity/api/brand/get?id=${id}`,
    method: 'get'
  });
}

// APP-商家端-小程序端用：查询所有展示在商家端的品牌
export function listAll(data = {}) {
  return withExtTenantIdRequest({
    url: '/soyoungzg/api/brand/listAllBriefForApp', // 原接口 /soyoungzg/api/brand/briefListAll
    method: 'post',
    data
  });
}
