<template>
  <div class="invoice-order">
    <div class="invoice__explain">
      <p>
        开票注意事项：<router-link v-if="false" :to="'/syoung/introduce/invoice'" target="_blank" class="color-b"
          >查看开票须知</router-link
        ><br />1、可开票订单：必须要满足两个条件，一是订单必须为【确认收货】完成的订单才可以进行开票；二是订单的实际收款金额必须大于等于10元(计算会扣除商品退款金额)；
        <br />2、订单类型：目前只支持大贸商品进行开票，海淘商品不支持开票；
        <br />3、账户类型：目前只支持采销类型的账户进行开票，赊销的账户不支持开票；
        <br />4、开票方： 自有仓为：【湖南水羊电子商务有限公司】，合作仓为：【长沙水羊网络科技有限公司】；
        <br />5、自有仓和合作仓需要分开开票，请在不同的栏目中进行开票申请。
      </p>
    </div>
    <div class="table-container">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="ownWarehouse+'('+ownNumber+')'" name="first"></el-tab-pane>
        <el-tab-pane :label="cooperativeWarehouse+'('+cooperativeNumber+')'" name="second"></el-tab-pane>
      </el-tabs>
      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="下单时间">
          <el-date-picker
            :default-time="['00:00:00', '23:59:59']"
            end-placeholder="结束日期"
            range-separator="至"
            start-placeholder="开始日期"
            type="daterange"
            v-model="createDate"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="订单合同主体">
          <div class="form-item">
            <el-select
              clearable
              size="small"
              style="width: 100%"
              v-model="distributorContractInfoIds"
              multiple
              placeholder="请选择（可多选）"
            >
              <el-option
                :key="idx"
                :label="item.label"
                :loading="orderOptionsLoading"
                :value="item.value"
                v-for="(item, idx) in contractList">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item label="品牌">
          <div class="form-item">
            <el-select
                clearable
                filterable
                size="small"
                style="width: 100%"
                v-model="brandIds"
                multiple
                placeholder="请选择（可多选）"
            >
              <el-option
                  :key="idx"
                  :label="item.name"
                  :loading="brandOptionsLoading"
                  :value="item.id"
                  v-for="(item, idx) in brandList">
              </el-option>
            </el-select>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button @click="onSubmit" type="primary" class="invoice__search">查询</el-button>
        </el-form-item>
      </el-form>
      <div class="invoice__btn">
       <span v-if="selectAll === '0'"> 已勾选开票金额 {{ price }} </span>
       <span v-else> 全部开票金额 {{ allPrice }} </span>
        <el-button @click="toHeader" class="invoice__btn--add" type="primary">申请开票</el-button>
        <el-button @click="submitAll" class="invoice__btn--add">全部开票</el-button>
        <span class="invoice__tips">全部开票最多一次可以处理500条订单</span>
      </div>
      <el-table
        :data="tableData"
        @select="handleselectRow"
        @select-all="handleselectAll"
        ref="multipleTable"
        style="width: 100%"
        tooltip-effect="dark"
        align="center"
      >
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column
          label="订单号"
          prop="orderNo"
          width="auto"
          align="center"
        ></el-table-column>
        <el-table-column label="订单时间" width="auto" align="center">
          <template slot-scope="scope">{{
            scope.row.createDate | parseTime('{y}-{m}-{d} {h}:{i}:{s}')
          }}</template>
        </el-table-column>
        <el-table-column
          label="商品数量"
          prop="quantity"
          width="auto"
          align="center"
        ></el-table-column>
        <el-table-column
          label="申请金额"
          prop="amount"
          width="auto"
          align="center"
        ></el-table-column>
        <el-table-column label="订单合同主体" width="auto" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.contractInfo">{{ scope.row.contractInfo.merchantTypeName + '-' + scope.row.contractInfo.contractInfoName }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          :current-page="formData.pageNo"
          :page-size="formData.pageSize"
          :page-sizes="[10, 20, 30, 40]"
          :total="total"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          background
          layout="total, sizes, prev, pager, next, jumper"
        ></el-pagination>
      </div>
    </div>
    <el-dialog title="开票确认" :visible.sync="dialogFormVisible" width="650px">
      <p class="dialog-p">共选择了{{ withoutContractOrderNum + withContractOrderNum }}个订单</p>
      <div class="dialog-cont">
        <div>
          <el-radio v-model="contractResultId" :label="withContractResultId">有开票主体订单（共计{{ withContractOrderNum }}个）</el-radio>
          <div class="dialog-cont--text">
            <p v-for="(item, index) in confirmContrantInfoList" :key="index">{{ item.merchantTypeName }}-{{ item.contractInfoName }}：{{ item.orderNum }}个订单，￥{{ item.totalAmount }}</p>
          </div>
        </div>
        <div>
          <el-radio v-model="contractResultId" :label="withoutContractResultId">无开票主体订单（共计{{ withoutContractOrderNum }}个）</el-radio>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="bindSure">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { canInvoiceOrderPageList, getInvoiceOrderType, canInvoiceOrderConfirm } from '@/api/invoice';
import { listOrderContractInfo } from '@/api/order/inquiry/list';
import { listAll as brandListAll } from '@/api/setting/commodity/brand';
export default {
  name: 'invoice-order',
  data() {
    return {
      ownWarehouse: '自有仓',
      cooperativeWarehouse: '合作仓',
      ownNumber: 0,
      cooperativeNumber: 0,
      selectAll: '0', // 是否全部开票
      tableData: [],
      multipleSelection: {},
      createDate: '',
      formData: {
        data: {
          startDate: '',
          orderBrandType: 'NORMAL',
          endDate: '',
        },
        pageNo: 1,
        pageSize: 10,
      },
      allPrice: 0,
      total: 0,
      dialogFormVisible: false,
      description: '',
      activeName: 'first',
      distributorContractInfoIds: [],
      contractList: [],
      orderBrandType: 'NORMAL',
      confirmContrantInfoList: [],
      contractResultId: '',
      withContractResultId: '', // 有开票主体结果ID
      withoutContractResultId: '', // 无开票主体结果ID
      withContractOrderNum: 0, // 有开票主体订单数
      withoutContractOrderNum: 0, // 无开票主体订单数
      brandOptionsLoading: false, // 品牌列表Loading
      brandList: [], // 品牌列表
      brandIds: [], // 品牌ID
    };
  },
  computed: {
    price() {
      let price = 0;
      for (const i in this.multipleSelection) {
        price += this.multipleSelection[i].amount;
      }
      return price.toFixed(2);
    },
  },
  created() {
    sessionStorage.removeItem('invoiceIds');
  },
  mounted() {
    this.getType();
    this.fetchBrandListAll();
    this.getList();
    this.getOrderList();
  },
  methods: {
    getType(){
      getInvoiceOrderType().then((res) => {
        this.ownWarehouse = res.data[0].brandTypeName || '自有仓';
        this.ownNumber = res.data[0].brandOrderNum || '0';
        this.cooperativeWarehouse = res.data[1].brandTypeName || '合作仓';
        this.cooperativeNumber = res.data[1].brandOrderNum || '0';
      });
    },
    getList() {
      const data = Object.assign({}, this.formData);
      canInvoiceOrderPageList(data).then((res) => {
        this.tableData = res.data.list;
        this.total = res.data.total;
        this.$nextTick(() => {
          this.tableChecked();
        });
      });
    },
    onSubmit() {
      if (this.createDate) {
        this.formData.data.startDate = this.createDate[0].getTime();
        this.formData.data.endDate = this.createDate[1].getTime();
      } else {
        this.formData.data.startDate = '';
        this.formData.data.endDate = '';
      }
      this.formData.data.distributorContractInfoIds = this.distributorContractInfoIds;
      this.formData.data.brandIds = this.brandIds;
      this.getList();
    },
    toHeader() {
      this.selectAll = '0';
      const multipleSelection = Object.keys(this.multipleSelection);
      if (multipleSelection.length === 0) {
        this.$message({
          type: 'warning',
          message: '请先选择发票',
        });
      } else {
        sessionStorage.setItem('izAllInvoice', '0');
        this.canInvoiceOrderConfirm(this.selectAll, multipleSelection);
      }
    },
    submitAll(){
      this.selectAll = '1';
      sessionStorage.setItem('izAllInvoice', '1');
      this.canInvoiceOrderConfirm(this.selectAll);
    },
    canInvoiceOrderConfirm(izAllInvoice, orderIds = []) {
      sessionStorage.setItem('orderIds', JSON.stringify(orderIds));
      canInvoiceOrderConfirm({
        orderIds,
        izAllInvoice,
        orderBrandType: this.formData.data.orderBrandType
      }).then(res => {
        if(res.code === '0'){
          const {
            withContractOrderNum = 0,
            withContractResultId = '',
            withoutContractOrderNum = 0,
            withoutContractResultId = '',
            confirmContrantInfoVOList = []
          } = res.data;
          if(withContractOrderNum > 0 && withoutContractOrderNum > 0) {
            this.dialogFormVisible = true;
            this.contractResultId = this.withContractResultId = withContractResultId;
            this.withoutContractResultId = withoutContractResultId;
            this.withContractOrderNum = withContractOrderNum;
            this.withoutContractOrderNum = withoutContractOrderNum;
            this.confirmContrantInfoList = confirmContrantInfoVOList;
          } else {
            const name = withoutContractOrderNum > 0 ? '/syoung/invoice/invoice-confirm-header' : '/syoung/invoice/invoice-have-header';
            const resultId = withoutContractOrderNum > 0 ? withoutContractResultId : withContractResultId;
            this.$router.push({
              name,
              query: {
                orderBrandType: this.formData.data.orderBrandType,
                resultId: resultId
              }
            });
          }
        }
      });
    },
    async bindSure() {
      const name = this.contractResultId === this.withContractResultId ? '/syoung/invoice/invoice-have-header' : '/syoung/invoice/invoice-confirm-header';
      this.$router.push({
        name,
        query: {
          orderBrandType: this.formData.data.orderBrandType,
          resultId: this.contractResultId
        }
      });
      this.dialogFormVisible = false;
    },
    handleSizeChange(val) {
      this.formData.pageSize = val;
      this.formData.pageNo = 1;
      this.getList();
    },
    handleCurrentChange(val) {
      this.formData.pageNo = val;
      this.getList();
    },
    handleselectRow(selection, row) {
      this.selectAll = '0';
      if (this.multipleSelection[row.id]) {
        this.$delete(this.multipleSelection, row.id);
      } else {
        this.$set(this.multipleSelection, row.id, row);
      }
    },
    handleselectAll(selection) {
      this.selectAll = '0';
      this.tableData.forEach((row, index) => {
        if (Object.prototype.hasOwnProperty.call(this.multipleSelection, row.id)) {
          selection.length ? null : this.$delete(this.multipleSelection, row.id);
        } else {
          this.$set(this.multipleSelection, row.id, row);
        }
      });
    },
    tableChecked() {
      this.tableData.forEach((row, index) => {
        if (Object.prototype.hasOwnProperty.call(this.multipleSelection, row.id)) {
          this.$refs.multipleTable.toggleRowSelection(row, true);
        } else {
          this.$refs.multipleTable.toggleRowSelection(row, false);
        }
      });
    },
    handleClick() {
        this.createDate = '';
        this.formData.data.startDate = '';
        this.formData.data.endDate = '';
        this.distributorContractInfoIds = [];
        this.formData.data.distributorContractInfoIds = [];
        this.brandIds = [];
        this.formData.data.brandIds = [];
        this.formData.pageNo = 1;
        this.formData.pageSize = 10;
        this.multipleSelection = {};
        this.activeName === 'first' ? this.formData.data.orderBrandType = 'NORMAL' : this.formData.data.orderBrandType = 'INTERNATION';
        this.activeName === 'first' ? this.orderBrandType = 'NORMAL' : this.orderBrandType = 'INTERNATION';
        this.getList();
        this.getOrderList();
      },
    // 订单合同主体列表
    getOrderList() {
      this.orderOptionsLoading = true;
      listOrderContractInfo({
        orderBrandType: this.orderBrandType
      })
      .then(rs => {
          if (rs && rs.data) {
            const res = rs.data.map(item => ({
              value: item.id,
              label: item.merchantTypeName + '-' + item.contractInfoName,
            }));
            this.contractList = res;
          }
        })
        .finally(() => {
          this.orderOptionsLoading = false;
        });
    },
    // 获取品牌列表
    fetchBrandListAll() {
      this.brandOptionsLoading = true;
      brandListAll().then(res => {
        this.brandList = res.data || [];
      }).finally(() => {
        this.brandOptionsLoading = false;
      });
    },
  },
};
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.invoice {
  &__explain {
    width: 1180px;
    background-color: #fffaf1;
    font-size: 14px;
    line-height: 20px;
    color: #e98e5e;
    padding: 10px;
    margin-bottom: 20px;
  }
  &__search {
    width: 64px;
    font-size: 14px;
    margin-left: 30px;
  }
  &__btn {
    margin-bottom: 20px;
    &--add {
      width: 100px;
      margin-left: 10px;
    }
  }
  &__tips{
    margin-left:10px;
  }
}
.invoice-order {
  font-size: 12px;
  color: #333;
  /deep/ .demo-form-inline {
    .el-range-separator {
      width: 8%;
    }
  }
  /deep/.el-table {
    font-size: 12px;
    th {
      height: 40px;
      padding: 0;
      background-color: #f3f3f3;
      .cell {
        line-height: 12px;
      }
    }
    .el-table-column--selection .cell {
      padding-left: 20px;
    }
  }
}
.color-b {
  color: #2d89da;
  &:hover {
    text-decoration: underline;
  }
}
.dialog{
  &-p{
    margin-bottom: 20px;
  }
  &-box {
    display: flex;
  }
  &-cont {
    /deep/ .el-radio__input.is-checked + .el-radio__label {
      color: #333;
    }
    /deep/ .el-radio {
      color: #333;
    }
    &--text {
      margin: 15px 50px;
      color: #101010;
      line-height: 26px;
      max-height: 160px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(144, 147, 153, .5);
        border-radius: 5px;
      }
    }
  }
  &-input{
    width: 300px;
    height: 120px;
    /deep/.el-textarea__inner{
      height: 100%;
    }
  }
}
.form-item {
  width: 240px;
}
</style>
